<template>
  <div
    ref="mainContainer"
    :class="`${campaignData.theme_type === 'without_card' ? 'without_card container-class' : 'container-class'}`"
    :style="{ backgroundImage: `url(${campaignData.bg_image})` }"
  >
    <div
      v-if="campaignData.theme_type === 'verticle' && campaignData.close_campaign == 'false'" 
      class="d-flex flex-column sub-container"
    >
      <div
        v-if="showErrorMessage"
        ref="errorMessage"
        class="card-view pd-5 d-xs-block"
        v-bind:style="cardStyle"
      />
      <div
        v-else
        class="
          card-view
          pd-5
          d-flex
          flex-direction-column
          align-items-center
          d-xs-block
        "
        v-bind:style="cardStyle"
      >
        <div class="scard-container2 text-center">
          <h2 v-bind:style="headerStyle" class="title-class">
            {{ campaignData.step1_title }}
          </h2>
          <p v-bind:style="textStyle" class="msg-txt pb-5">
            {{ campaignData.step1_description }}
          </p>
        </div>
        <img
          :src="campaignData.spinner_front_image"
          style="height: 300px; width: 300px"
          class="mt-20 mb-20"
        />
        <div class="scard-container2 text-center">
          <div class="w-100">
            <button
              v-bind:style="buttonStyle"
              @click="showModal"
              class="submit-btn"
            >
              {{ campaignData.step1_button_text }}
            </button>
          </div>
        </div>
        <button
          v-if="
            campaignData.prize_popup_status == 'true' &&
            campaignData.prize_button_text
          "
          @click="showPriceModal"
          v-bind:style="priceStyle.priceButtonStyle"
          class="prize_button prize_button_vertical"
        >
          {{ campaignData.prize_button_text }}
        </button>
      </div>
      <power-by
        v-if="campaignData.powered_by_text"
        :campaignData="campaignData"
      />
    </div>
    
    <div v-else-if="campaignData.theme_type === 'without_card' && campaignData.close_campaign == 'false'">
      <div
        v-if="showErrorMessage"
        ref="errorMessage"
        class="card-view pd-5 d-xs-block"
        v-bind:style="cardStyle"
      />
      <div
        v-else
        class="
          card-view
          pd-5
          d-flex
          flex-direction-column
          align-items-center
          d-xs-block
        "
        v-bind:style="cardStyle"
      >
        <img
          :src="campaignData.campaign_image"
          style=""
          class="theme3_top_img"
        />
        <div class="scard-container2 text-center">
          <h2 v-bind:style="headerStyle" class="title-class">
            {{ campaignData.step1_title }}
          </h2>
          <p v-bind:style="textStyle" class="msg-txt pb-5">
            {{ campaignData.step1_description }}
          </p>
        </div>
        <img
          :src="campaignData.spinner_front_image"
          class="mt-20 mb-20 first_step_img"
        />
        <div class="scard-container2 text-center">
          <div class="w-100">
            <button
              v-bind:style="buttonStyle"
              @click="showModal"
              class="submit-btn"
            >
              {{ campaignData.step1_button_text }}
            </button>
          </div>
        </div>
        <button
          v-if="
            campaignData.prize_popup_status == 'true' &&
            campaignData.prize_button_text
          "
          @click="showPriceModal"
          v-bind:style="priceStyle.priceButtonStyle"
          class="prize_button prize_button_vertical"
        >
          {{ campaignData.prize_button_text }}
        </button>
      </div>
      <power-by
        v-if="campaignData.powered_by_text"
        :campaignData="campaignData"
      />
    </div>
    <div v-else class="d-flex flex-column sub-container">
      <div v-if="campaignData.close_campaign == 'true'">
        <div
          class="
            card-view
            pd-5
            d-flex
            flex-direction-column
            align-items-center
            d-xs-block
          "
          v-bind:style="cardStyle"
        >
          <div class="scard-container2 text-center">
              <h2 v-bind:style="headerStyle" class="title-class">
                {{ campaignData.close_message }}
              </h2>
          </div>
        </div>
     </div>
      <div v-else>
        <div
          v-if="showErrorMessage"
          ref="errorMessage"
          class="card-view pd-5 d-xs-block"
          v-bind:style="cardStyle"
        />
        <div
          v-else
          class="card-view pd-5 d-flex align-items-center d-xs-block"
          v-bind:style="cardStyle"
        >
          <img
            :src="campaignData.spinner_front_image"
            style="height: 300px; width: 300px"
          />
          <div class="scard-container2 text-center">
            <h2 v-bind:style="headerStyle" class="title-class">
              {{ campaignData.step1_title }}
            </h2>
            <p v-bind:style="textStyle" class="msg-txt pb-5">
              {{ campaignData.step1_description }}
            </p>
            <div class="w-100">
              <button
                v-bind:style="buttonStyle"
                @click="showModal"
                class="submit-btn"
              >
                {{ campaignData.step1_button_text }}
              </button>
            </div>
          </div>
          <button
            v-if="campaignData.prize_popup_status == 'true'"
            @click="showPriceModal"
            v-bind:style="priceStyle.priceButtonStyle"
            class="prize_button"
          >
            {{ campaignData.prize_button_text }}
          </button>
        </div>
        <power-by
          v-if="campaignData.powered_by_text"
          :campaignData="campaignData"
        />
      </div>

    </div>
    


    <form-modal
      :tnc="tnc"
      @close="closeModal"
      @onSubmit="onSubmit"
      v-show="isModalVisible"
      :popupopFormData="popupopForm"
      :popupTitle="campaignData.popup_title"
      :popupSubText="campaignData.popup_sub_text"
      :popupBtnTxt="campaignData.popup_button_text"
    />
    <price-modal
      :priceData="priceData"
      @close="priceCloseModal"
      v-show="isPriceModalVisible"
    />
    <cookie-modal
      :popupData="{}"
      @close="closeCookieModal"
      v-show="isCookieModalVisible"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";
import VueTypes from "vue-types";
import { getUrl } from "@/services/helperFunctions";


import PowerBy from "@/components/powerby/PowerBy.vue";
import FormModal from "@/components/frontmodals/FormModal.vue";
import PriceModal from "@/components/frontmodals/PriceModal.vue";
import CookieModal from "@/components/frontmodals/CookieModal.vue";

import {
  getOS,
  getIP,
  getDevice,
  getBrowser,
} from "@/services/helperFunctions";

export default {
  name: "Page1",
  components: {
    PowerBy,
    FormModal,
    PriceModal,
    CookieModal,
  },
  props: {
    showErrorMessage: VueTypes.bool.def(false),
  },
  computed: {
    campaignData: function () {
      const { campaignData } = this.$store.getters;
      return campaignData;
    },
    popupopForm: function () {
      const { campaignData } = this.$store.getters;
      return _.map(Object.values(campaignData.popup_data), (v) => ({
        ...v,
        type: this.getType(v.name),
        class: this.getClass(v.name),
      }));
    },
    tnc: function () {
      const { campaignData } = this.$store.getters;
      return {
        label: campaignData.popup_terms_condition_label,
        title: campaignData.popup_terms_condition_title,
        value: campaignData.popup_terms_condition_value,
        warning: campaignData.popup_terms_condition_warning,
      };
    },
    priceData: function () {
      const { campaignData } = this.$store.getters;
      return {
        pdata: campaignData.spinner,
      };
    },
    headerStyle: function () {
      return this.$store.getters.headerStyle;
    },
    textStyle: function () {
      return this.$store.getters.textStyle;
    },
    buttonStyle: function () {
      return this.$store.getters.buttonStyle;
    },
    cardStyle: function () {
      return this.$store.getters.cardStyle;
    },
    errorMessage: function () {
      return this.$refs.errorMessage;
    },
    priceStyle: function () {
      return this.$store.getters.priceStyle;
    },
  },
  data() {
    return {
      ip: "",
      countryName: "",
      os: getOS(),
      device: getDevice(),
      isModalVisible: false,
      isPriceModalVisible: false,
      isCookieModalVisible: false,
      browser: getBrowser(),
      showMessage: false,
    };
  },
  methods: {
    ...mapActions(["subscribe"]),
    async onSubmit(data) {
      try {
        const authData = {
          ...data,
          os: this.os,
          ip_address: this.ip,
          device: this.device,
          browser: this.browser,
          slug: this.$route.params.slug,
          country: this.countryName,
        };
        const res = await this.subscribe(authData);
        if (res) {
          this.closeModal();
          this.$router.push({ path: `/progress/${this.$route.params.slug}` });
          this.$store.commit("setPageCount", 2);
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      }
    },
    async updateData() {
      try {
        const ipAddress = await getIP();
        this.ip = ipAddress.ip;
        this.countryName = ipAddress.countryName;
      } catch (error) {
        throw new Error(error);
      }
      this.os = getOS();
      this.device = getDevice();
      this.browser = getBrowser();
    },
    closeModal() {
      this.isModalVisible = false;
    },
    priceCloseModal() {
      this.isPriceModalVisible = false;
    },
    showPriceModal() {
      this.isPriceModalVisible = true;
    },
    showModal() {
      this.isModalVisible = true;
    },
    showCookieModal() {
      this.isCookieModalVisible = true;
    },
    closeCookieModal() {
      this.isCookieModalVisible = false;
    },
    getType(type) {
      if (type == "phone" || type == "zip_code") {
        return "number";
      } else if (type == "email") {
        return "email";
      } else {
        return "text";
      }
    },
    getClass(name) {
      if (name == "zip_code") {
        return "small_width";
      } else if (name == "city") {
        return "half_width";
      } else {
        return "";
      }
    },
    checkRedirect() {
      const { close_redirect_url = "" } = this.campaignData;
      if (close_redirect_url) {
        setTimeout(() => {
          window.location.href = close_redirect_url;    
          return false;
        }, 5000);
      }
    },
  },
  mounted() {
    this.updateData();
    setTimeout(() => {
      if (this.showErrorMessage) {
        this.errorMessage.innerHTML =
          this.campaignData?.not_found_message ?? "";
      }
    }, 500);

    if (this.campaignData.show_cookie_popup == "true") {
      let name = "campaign_cookie=";
      let cookie = true;

      let allCookieArray = document.cookie.split(";");

      for (let i = 0; i < allCookieArray.length; i++) {
        let temp = allCookieArray[i].trim();

        if (temp.indexOf(name) == 0) {
          cookie = false;
        }
      }

      if (cookie) {
        this.showCookieModal();
      }
    }
  },
  created() {
    if(this.campaignData.close_campaign == 'true') {
      this.checkRedirect();
    }
  }
};
</script>
<style scoped>
.form-control::placeholder {
  color: rgb(45, 45, 45);
  opacity: 1;
}
</style>